import React from 'react'
import { graphql } from 'gatsby'
import SliceZone from '../components/slice-zone'
import Layout from '../components/layout'
import {Helmet} from "react-helmet";

const Enroll = ({ data }) => {
  if (!data) return null

  const page = data.prismicEnroll.data

  return (
      <Layout>
          <Helmet>
            <title>{page.title.text}</title>
            <meta name="description" content={page.description.text} />
          </Helmet>
          <section className="enroll-title">
            <h1 className="mt-100 mb-100 text-blue text-center">{page.header.text}</h1>
          </section>
        <SliceZone slices={page.body} />
        <section className="enroll-footer">
            <div className="mb-100"></div>
        </section>
      </Layout>
  )
}

export const query = graphql`
query EnrollQuery($uid: String) {
    prismicEnroll(uid: {eq: $uid}) {
        uid
        type
        id
        data {
            title {
                text
            }
            description {
                text
            }
            header {
                text
            }
            body {
                ... on PrismicEnrollBodyEnrollHeader {
                    id
                    slice_type
                    slice_label
                    primary {
                        step
                        header1 {
                            text
                        }
                    }
                }
                ... on PrismicEnrollBodyEnrollInstructions {
                    id
                    slice_type
                    slice_label
                    primary {
                        instructions {
                            text
                            raw
                        }
                    }
                }
                ... on PrismicEnrollBodyEnrollDocumentLink {
                    id
                    slice_type
                    slice_label
                    primary {
                        document {
                            uid
                            url
                        }
                        link_text {
                            text
                        }
                    }
                }
            }
        }
    }
}
`

export default Enroll